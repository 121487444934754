'use strict'

const _confirmParams = {
  title: 'Confirmation',
  buttonSize: 'sm',
  okTitle: 'Continuer',
  okVariant: 'danger',
  cancelTitle: 'Annuler',
  cancelVariant: 'light',
  headerClass: 'pt-2 pb-1',
  footerClass: 'pt-0 pb-1',
  centered: true,
  autoFocusButton: 'ok'
}

const userAgent = navigator.userAgent
const _isPDA = () => userAgent.indexOf('IPDA030') !== -1 || userAgent.indexOf('SPA43LTE') !== -1 || userAgent.indexOf('EDA51') !== -1 || userAgent.indexOf('UA-Stock') !== -1
const _isAndroid = () => userAgent.indexOf('Android') !== -1
const _isIOS = () => userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1 || userAgent.indexOf('iPod') !== -1
const _isMobile = () => _isAndroid(userAgent) || _isIOS(userAgent)

export default {
  confirmParams: () => _confirmParams,
  isPDA: _isPDA,
  isAndroid: _isAndroid,
  isIOS: _isIOS,
  isMobile: _isMobile,
  currentWhereDevice: () => {
    if (_isPDA()) return 'PDA'
    if (_isMobile()) return 'Mobile'
    return 'Desktop'
  }
}
