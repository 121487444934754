'use strict'

import { ApiDeleev, ApiUtils } from '../index'
import mProducts from '@/mixins/products'
import mCenters from '@/mixins/centers'

const _formatProduct = (centerId, centerFreeZoningEnabled, remoteProduct) => {
  const productCenter = remoteProduct.by_centers.find(o => o.center === centerId)
  remoteProduct.area = productCenter.area
  remoteProduct.shelf = productCenter.shelf
  remoteProduct.tier = productCenter.tier
  remoteProduct.stock_quantity = productCenter.stock_quantity
  remoteProduct.quantity_ideal = productCenter.quantity_ideal
  remoteProduct.quantity_threshold = productCenter.quantity_threshold
  remoteProduct.visual_stock_quantity = productCenter.visual_stock_quantity
  remoteProduct.real_stock_quantity = productCenter.real_stock_quantity
  if (remoteProduct.visual_stock_quantity === null) {
    remoteProduct.visual_stock_quantity = productCenter.real_stock_quantity
  }
  remoteProduct.last_stock_checked = productCenter.last_stock_checked
  remoteProduct.oldest_active_last_stock_added_at = productCenter.oldest_active_last_stock_added_at

  remoteProduct.selling_name_ext = remoteProduct.selling_name_ext || mProducts.getSellingNameExt(remoteProduct)
  if (typeof productCenter.is_free_zoning_possible === 'boolean') {
    remoteProduct.is_free_zoning_possible = productCenter.is_free_zoning_possible
  } else {
    remoteProduct.is_free_zoning_possible = remoteProduct.is_free_zoning_enabled && centerFreeZoningEnabled
  }

  const productSite = remoteProduct.by_sites.find(o => o.site === 1)
  remoteProduct.gross_selling_price = productSite.gross_selling_price

  if (remoteProduct.dlc_management_enabled) {
    let _maxRetentionDate = new Date()
    _maxRetentionDate.setDate(_maxRetentionDate.getDate() + remoteProduct.retention_periods)
    remoteProduct.dlc_max_retention_date = _maxRetentionDate.toISOString()
  }

  remoteProduct.supplierreferences = remoteProduct.supplierreferences || remoteProduct.supplierreference_set || []
  if (!remoteProduct.supplier_quantity_ideal_label) {
    if (remoteProduct.quantity_ideal && remoteProduct.supplierreferences) {
      remoteProduct.supplier_quantity_ideal_label = mProducts.getQuantityColisLabel(remoteProduct.quantity_ideal, remoteProduct.supplierreferences[0] || {})
    }
  }

  if (remoteProduct.barcode_package && typeof remoteProduct.barcode_package === 'string') {
    remoteProduct.barcode_package = JSON.parse(remoteProduct.barcode_package)
  }

  return remoteProduct
}
const _formatProducts = (centerId, remoteProducts) => {
  const _centerFreeZoningEnabled = mCenters.getCenter(centerId).is_free_zoning_enabled
  return remoteProducts.map(remoteProduct => _formatProduct(centerId, _centerFreeZoningEnabled, remoteProduct))
}

const _getMainZone = (areas) => {
  let _mainZoneId, _addedDate, _lastAddedDate
  areas.forEach(o => {
    if (o.real_stock <= 0) return
    if (!o.last_stock_added_at) return
    _addedDate = (new Date(o.last_stock_added_at)).getTime()
    if (!_lastAddedDate || _addedDate < _lastAddedDate) {
      _mainZoneId = o.id
      _lastAddedDate = _addedDate
    }
  })
  return _mainZoneId
}
const _getMainZoneForMeS = (areas) => {
  let _mainZone = areas.find(o => o.real_stock > 0)
  if (_mainZone && mProducts.isFreshZones(_mainZone.area)) return null
  return _mainZone
}

const LAST_STOCK_DURATION = 12 * 60 * 60 * 1000 // 12h
const _formatAreas = (remoteAreas) => {
  remoteAreas.sort((a, b) => (+b.is_stock_pilling_priority_area - +a.is_stock_pilling_priority_area) ||
    (b.real_stock - a.real_stock) ||
    ((new Date(b.last_stock_added_at || b.last_stock_checked_at)).getTime() - (new Date(a.last_stock_added_at || a.last_stock_checked_at)).getTime())
  )
  const _mainZoneId = _getMainZone(remoteAreas)
  const _mainZoneForMeS = _getMainZoneForMeS(remoteAreas)
  remoteAreas.forEach(o => {
    o.added_in_last_12hours = o.last_stock_added_at && (new Date()).getTime() - (new Date(o.last_stock_added_at)).getTime() < LAST_STOCK_DURATION
    o.is_main_zone = o.id === _mainZoneId
    o.is_main_zone_for_mes = _mainZoneForMeS && _mainZoneForMeS.id === o.id
    return o
  })
  return remoteAreas
}

const _formatAreasZones = (classificationZones) => {
  let _areas = {}
  classificationZones.forEach(classification => {
    classification.areas.forEach(area => {
      if (!_areas[area]) _areas[area] = []
      _areas[area].push(classification.classification_id)
    })
  })
  return {
    classifications: classificationZones,
    areas: _areas
  }
}

const _formatOrderForm = (remoteData) => {
  let _supplierReference, _orderProduct
  let _product = remoteData.product

  _product.selling_name_ext = _product.selling_name_ext || mProducts.getSellingNameExt(_product)
  _product.area_classifications = _product.area_classifications || []
  _product.stockpilling_warning = _product.stockpilling_warning || ''

  if (_product.dlc_management_enabled) {
    let _maxRetentionDate = new Date()
    _maxRetentionDate.setDate(_maxRetentionDate.getDate() + _product.retention_periods)
    _product.dlc_max_retention_date = _maxRetentionDate.toISOString()
  }

  _product.supplierreferences = _product.supplierreferences || _product.supplierreference_set

  if (remoteData.order_form) {
    if (remoteData.order_form.supplier === 50) {
      _supplierReference = _product.supplierreferences[0]
      _supplierReference.packaging = 1
    } else {
      _supplierReference = _product.supplierreferences.find(function (o) { return o.supplier === remoteData.order_form.supplier })
    }

    _product.supplier_order = remoteData.order_form
  } else {
    _supplierReference = _product.supplierreferences[0]
  }

  if (_product.product_center_areas && _product.product_center_areas.length) {
    _product.product_center_areas = _formatAreas(_product.product_center_areas)
  }

  _orderProduct = remoteData.order_form_item || {
    supplier: _supplierReference.supplier || 0,
    buying_type: _supplierReference.buying_type || 'pack',
    buying_method: _supplierReference.buying_method || 'unit',
    packaging: _supplierReference.packaging || 1,
    quantity: 0,
    final_quantity: 0,
    added: false
  }
  if (!_orderProduct.supplier) _orderProduct.supplier = _supplierReference.supplier || 0
  if (!_orderProduct.packaging) _orderProduct.packaging = _supplierReference.packaging || 1
  if (!_orderProduct.buying_type) _orderProduct.buying_type = _supplierReference.buying_type || 'pack'
  if (!_orderProduct.buying_method) _orderProduct.buying_method = _supplierReference.buying_method || 'unit'

  if (typeof _orderProduct.delivery_note_quantity !== 'undefined' && _orderProduct.delivery_note_quantity !== null) {
    _orderProduct.final_quantity = +_orderProduct.delivery_note_quantity
  } else {
    _orderProduct.final_quantity = _orderProduct.buying_type === 'free' ? _orderProduct.quantity : (_orderProduct.quantity * _orderProduct.packaging)
  }
  if (_orderProduct.delivery_note_quantity_buying && _orderProduct.delivery_note_quantity_buying_label) {
    _orderProduct.delivery_buying_quantity_label = `${_orderProduct.delivery_note_quantity_buying} ${_orderProduct.delivery_note_quantity_buying_label}`
  } else {
    _orderProduct.delivery_buying_quantity_label = `${_orderProduct.quantity} colis`
  }
  _orderProduct.quantity_to_add = _orderProduct.final_quantity
  if (_orderProduct.added) {
    _orderProduct.quantity_to_add -= _orderProduct.added_quantity
    if (_orderProduct.quantity_to_add < 0) _orderProduct.quantity_to_add = 0
  }

  _product.supplier_order_product = _orderProduct

  remoteData.stats = remoteData.stats || {}
  _product.supplier_order_products_total = remoteData.stats.total || 0
  _product.supplier_order_products_not_added = remoteData.stats.todo || 0
  _product.prep_message = remoteData.prep_message

  if (!_product.supplier_quantity_ideal_label) {
    if (_product.quantity_ideal && _supplierReference) {
      _product.supplier_quantity_ideal_label = mProducts.getQuantityColisLabel(_product.quantity_ideal, _supplierReference)
    }
  }

  return _product
}

export default {
  getProducts (centerId, params, defaultMsg) {
    // ids / disponibility / barcode / supplier_id
    const _params = Object.assign({
      disponibility: 'order'
    }, params || {})
    return ApiUtils.promiseGet(ApiDeleev, '/admin-products/nesteds/', { params: _params }).then(function (response) {
      if (response.results) {
        const _products = _formatProducts(centerId, response.results.filter(o => o.disponibility === 'order'))
        if (params.barcode) {
          if (_products.length) {
            if (params.supplier_id) {
              return _products[0]
            } else {
              return _products.find(o => o.supplierreferences && o.supplierreferences.length && [211, 306, 521].indexOf(o.supplierreferences[0].supplier) === -1) || _products[0]
            }
          }
        } else {
          return _products
        }
      }
      throw new Error(defaultMsg || `Erreur lors de récupération des produits !`)
    })
  },
  getProductByBarcode (centerId, barcode, supplierId) {
    let _params = {
      barcode: barcode,
      visual_stock_quantity: 1
    }
    if (supplierId) _params.supplier_id = supplierId
    return this.getProducts(centerId, _params, `Il n'y a pas de produit avec ce code barre ${barcode} !`)
  },
  updateProduct (productId, data) {
    return ApiUtils.promisePatch(ApiDeleev, `/admin-products/nesteds/${productId}/`, data)
  },
  bulkUpdateProduct (data) {
    return ApiUtils.promisePost(ApiDeleev, '/admin-products/products/bulk/', data)
  },
  alterStockProduct (productId, data, centerId) {
    data.stock_checked = true
    return ApiUtils.promisePost(ApiDeleev, `/admin-products/nesteds/${productId}/alter_stock/`, data, { params: { visual_stock_quantity: 1 } }).then(function (response) {
      if (response) {
        return _formatProduct(centerId, mCenters.getCenter(centerId).is_free_zoning_enabled, response)
      }
      throw new Error(`Erreur !`)
    })
  },
  rezoningProductStock (productId, data, centerId) {
    return ApiUtils.promisePost(ApiDeleev, `/admin-products/nesteds/${productId}/rezoning/`, data, { params: { visual_stock_quantity: 1 } }).then(function (response) {
      if (response) {
        return _formatProduct(centerId, mCenters.getCenter(centerId).is_free_zoning_enabled, response)
      }
      throw new Error(`Erreur !`)
    })
  },
  getProductCenterAreas (centerId, params) {
    let _params = Object.assign({
      center_ids: centerId,
      offset: 0,
      limit: 100
    }, params || {})
    return ApiUtils.promiseGet(ApiDeleev, '/admin-products/product-center-areas/', { params: _params }).then(function (response) {
      if (response.results) {
        return _formatAreas(response.results)
      }
      throw new Error(`Erreur lors de récupération des zones !`)
    })
  },
  getAreaClassifications (params) {
    return ApiUtils.promiseGet(ApiDeleev, '/admin-products/area-classifications/', { params: params })
  },
  getAreaClassificationsZones (centerId, params) {
    let _params = Object.assign({
      center_id: centerId
    }, params || {})
    return ApiUtils.promiseGet(ApiDeleev, '/admin-products/area-classifications/zones/', { params: _params }).then(function (response) {
      if (response) {
        return _formatAreasZones(response)
      }
      throw new Error(`Erreur lors de récupération des classicifications des zones !`)
    })
  },
  searchOrders (params) {
    return ApiUtils.promiseGet(ApiDeleev, '/processing-orders/search/', { params: params })
  },
  searchOrderProducts (orderId) {
    return ApiUtils.promiseGet(ApiDeleev, `/processing-orders/${orderId}/`, { params: { ser: 'last_prep' } })
  },
  restockProductStock (orderId, data, centerId) {
    return ApiUtils.promisePost(ApiDeleev, `/processing-orders/${orderId}/restock/`, data)
  },
  getSupplierOrder (orderId, params) {
    return ApiUtils.promiseGet(ApiDeleev, `/order_forms/${orderId}/`, { params: params || {} })
  },
  getSupplierOrderItemInfo (barcode, productId, orderFormId, forceSupplierInternal) {
    let _params = {}
    if (barcode) _params.barcode = barcode
    else if (productId) _params.product_id = productId
    if (orderFormId) _params.order_form_id = orderFormId
    if (forceSupplierInternal) _params.force_supplier_internal = 1

    return ApiUtils.promiseGet(ApiDeleev, '/order_forms/info/', { params: _params }).then(function (response) {
      if (response && response.product) {
        return _formatOrderForm(response)
      }
      throw new Error('Oups, pas de produit ou pas de commande fournisseur en cours pour ce code barre')
    })
  },
  updateSupplierOrderProduct (data) {
    return ApiUtils.promisePost(ApiDeleev, '/order_forms/arrival/', data)
  },
  bulkUpdateAreaClassification (data) {
    return ApiUtils.promisePost(ApiDeleev, '/admin-products/preciseareaclassification/bulk/', data)
  },
  getProductLogs (productId, params) {
    return ApiUtils.promiseGet(ApiDeleev, `/admin-products/nesteds/${productId}/logs/`, { params: params || {} })
  }
}
