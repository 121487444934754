<template>
  <div id="appWrap" :class="{ 'hide-menu': !showMenu }">
    <VueHeader v-if="showMenu" @logout="logout" :user="user" />
    <router-view v-if="appIsReady" class="app-wrapper" @logout="logout" :style="{ 'min-height': wrapMinHeight + 'px' }" />
    <router-view v-if="appIsReady" class="app-wrapper-modal" name="modal" />
    <VueFooter />
  </div>
</template>

<script>
import store from './store'
import eventEmitter from './event-emitter'
import VueHeader from '@/components/Header.vue'
import VueFooter from '@/components/Footer.vue'

const _barcodeToZone = (barcode) => {
  if (barcode.length === 14) {
    const _parts = barcode.split('-')
    if (_parts.length === 3) {
      return { area: +_parts[0], shelf: +_parts[1], tier: +_parts[2] }
    }
  }
  return false
}
const _barcodeToCrate = (barcode) => {
  if (barcode.length === 5) {
    const _parts = barcode.split('-')
    if (_parts.length === 2) {
      return { code: barcode }
    }
  }
  return false
}

let _gs1RegEx = /^01(\d{14})17(\d{6})$/
window.barcodeRead = (barcode, addressMac, serialNumber) => {
  barcode = barcode.replace(/§/g, '-')

  let orderFormId
  if (barcode.indexOf('ofid:') === 0) {
    let _parts = barcode.split(':')
    if (_parts.length === 3) {
      barcode = _parts[2]
      orderFormId = +_parts[1]
    }
  }

  // Frichti GS1/gtin (prefix 01 puis 14 digits) et expiration date (prefix 17 + 6 digits)
  if (barcode.length === 24) {
    let _match = barcode.match(_gs1RegEx)
    if (_match && _match[1]) {
      barcode = _match[1].slice(2)
    }
  }

  barcode && eventEmitter.emit('barcode:scanned', {
    barcode: barcode,
    order_form_id: orderFormId,
    zone: _barcodeToZone(barcode),
    crate: _barcodeToCrate(barcode),
    mac_address: addressMac,
    serial_number: serialNumber
  })
}

export default {
  name: 'App',
  components: {
    VueHeader,
    VueFooter
  },
  watch: {
    $route (to, from) {
      this.showMenu = this.displayHeader()
    }
  },
  data () {
    return {
      wrapMinHeight: 400,
      appIsReady: false,
      showMenu: true,
      isLogged: false,
      user: {}
    }
  },
  created () {
    store.lookupCenter(() => {
      this.appIsReady = true
    })

    eventEmitter.on('auth:updated', this.initUser)
    this.initUser()
  },
  mounted () {
    this.$nextTick(() => {
      this.setWrapMinHeight()
    })
  },
  methods: {
    setWrapMinHeight () {
      this.wrapMinHeight = window.innerHeight - 115
    },
    displayHeader () {
      return this.isLogged && !(
        (this.$router.currentRoute.name === 'stocks' && this.$router.currentRoute.params.action !== 'menu') ||
        this.$router.currentRoute.name === 'tests-trial'
      )
    },
    logout () {
      store.logout()
    },
    initUser () {
      this.isLogged = store.isLogged()
      if (this.isLogged) {
        this.user = store.getUser()
      } else {
        this.user = {}
        const _isOfflineRoutes = ['registration', 'login', 'test-pda-read-barcode', 'bluetooth-spp-init'].indexOf(this.$router.currentRoute.name) !== -1
        !_isOfflineRoutes && this.$router.push('/login')
      }
      this.showMenu = this.displayHeader()
    }
  }
}
</script>
